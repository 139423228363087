.Main {
    overflow: hidden;
}

.Main-images {
    display: flex;
    overflow: hidden;
    justify-content: center;
    width: 50%;
}

@media (width < 900px) {
    .Main-images {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .Main-pepes {
        width: 100%;
    }
}
